body {
  margin: 0;
  padding: 0;
  /* background-color: white; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-family: "Trueno";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* fontssss */
@font-face {
  font-family: 'Trueno';
  src: local('Trueno'), url(/static/media/truenolt.e5a66b56.otf) format('woff');
}


.capitalize{
  text-transform: capitalize;
}


/* Loader */
.loader-full-screen,
.loader-full-section
 {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.loader-full-section{
  position: absolute;
}
.loader-full-screen .cover,
.loader-full-section .cover{
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background: rgba(255, 255, 255, .9)
}
.loader-fixed {
  display: inline-block;
  background: white;
  border-radius: 50%;
  padding: 9px 10px 5px 10px;
  top: 50px;
  position: fixed;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  z-index: 99999999;
}
.carousel .thumb img { 
  width: 100% !important; 
  height: 100% !important;
 }

.carousel .slide img {
  max-height: 800px;
  height: 100%;
  width: 100%;
}

.foot {
  /* background-image: url(./yellow.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: #fdb715;
  height: 120px;
  max-width:100%;
  margin: 0 !important;
  /* background: linear-gradient(to top, #FFFF, #1d3768); */
  display: flex;
  justify-content: space-evenly ;
}
.foot h4,h2 {
  color: #fff;
}

.login-logo {
  width: 150px;
  margin: 0 auto 20px;
  position: relative;
}
.login-logo img {
  width: 100%;
}
.login-logo h2 {
  margin: 6px 0 0 0;
  letter-spacing: 0.05em;
  font-size: 22px;
  color: #24205978;
  font-weight: 100;
  border-top: 1px solid #24205938;
  padding-top: 6px;
  font-family: sans-serif;
}
.login-logo-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.img-container{
  position: relative;
}

.img-container img {
  display: block;
  width: 100%;
  max-width: 2500px;
  margin: 0 auto;
}

.navbar-text {
  color: #1d3768;
  font-weight: 'bolder';
}

.services-table {
  margin-top: 20px;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0 0 20px #00000012;
}
.services-table thead tr {
  background: #d4d4d4;
}
.services-table thead tr th {
  font-weight: bold;
  color: #000;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
}
.services-table tbody tr td {
  font-weight: normal;
  color: #333;
  -webkit-font-smoothing: antialiased;
}
.services-table tbody tr:hover {
  background: #e0e0e0;
  cursor: pointer;
}
.chip {
  height: 300px;
  background-color: #fdb715 !important;
}
.services-list{
  align-items: center;
  margin: auto;
  overflow: hidden !important;
}
.service-check{
  width: 50%;
}
.extra-services {
  display: flex;
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
}
.extra-margin {
  margin-left: 30px !important;
  padding-right: 12px;
}
.extra-services-text {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .order-koraik-width{
    width: 100% !important;
    font-size: larger;
  }
  .service-check{
    width: 100% !important;
    margin: 10px;
  }
  .extra-services {
    display: flex;
    flex-direction: column
  }
  .extra-margin{
    margin: 0px;
  }
}
.order-koraik-width{
  width: 20%;
  font-size: larger !important;
}

.slider-wrapper :hover {
  opacity: 0.9;
}

.overlay-yellow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fdb715;
  cursor: pointer;
}

.overlay-blue {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #1d3768;
  cursor: pointer;
}

.container:hover .overlay-blue,
.container:hover .overlay-yellow
{
  opacity: 1;
}

.text {
  color: white;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.carousel {
  direction: ltr;
}
.pdf-body {
    margin-right: auto; /* 1 */
    margin-left:  auto; /* 1 */
    max-width: 329mm; /* 2 */
    padding-right: 10px; /* 3 */
    padding-left:  10px; /* 3 */
    direction: rtl;
}
.text-pdf {
    margin: 3px;
    font-size: 18px;
    text-align: right;
}
.text-key {
    font-weight: 600;
}
.tableText {
    margin: 5px;
    font-size: 16px;
    width: 25%;
    text-align: center; 
}
.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto 0px;
    padding-right: 20px;
}
.image {
    margin: 20px 0px 20px 20px;
    width: 70px;
    height: 70px;
}
.logoFont {
    margin: 25px 5px 30px 20px;
    width: 150px;
    height: 50px;
}
.header {
    font-size: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-top: 20px;
}
.tableTitle {
    display: flex;
    font-size: 20px;
}
.table {
    margin: 20px
}
.tableHeader {
    background-color: grey;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px
}
.tableRow {
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px;
}
.subTotaldiv {
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row-reverse;
}
.breakLine {
    height: 1px;
    background-color: black;
    margin: 10px 25px 50px 25px
}
.grandTotal {
    text-align: left;
    margin-left: 20px;
}
.grandTotalText {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: grey
}
.pageNumber {
    position: absolute;
    font-size: 12px;
    bottom: 30px;
    left: 0px;
    right: 0px;
    text-align: center;
    color: grey;
}
.details-pdf {
    display: flex;
    flex-direction: column;
    color:black;
    padding-left: 20px;
}

.notes {
    margin-right: 20px
}
.notes p {
    margin-right: 10px
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
