.pdf-body {
    margin-right: auto; /* 1 */
    margin-left:  auto; /* 1 */
    max-width: 329mm; /* 2 */
    padding-right: 10px; /* 3 */
    padding-left:  10px; /* 3 */
    direction: rtl;
}
.text-pdf {
    margin: 3px;
    font-size: 18px;
    text-align: right;
}
.text-key {
    font-weight: 600;
}
.tableText {
    margin: 5px;
    font-size: 16px;
    width: 25%;
    text-align: center; 
}
.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto 0px;
    padding-right: 20px;
}
.image {
    margin: 20px 0px 20px 20px;
    width: 70px;
    height: 70px;
}
.logoFont {
    margin: 25px 5px 30px 20px;
    width: 150px;
    height: 50px;
}
.header {
    font-size: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-top: 20px;
}
.tableTitle {
    display: flex;
    font-size: 20px;
}
.table {
    margin: 20px
}
.tableHeader {
    background-color: grey;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px
}
.tableRow {
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px;
}
.subTotaldiv {
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row-reverse;
}
.breakLine {
    height: 1px;
    background-color: black;
    margin: 10px 25px 50px 25px
}
.grandTotal {
    text-align: left;
    margin-left: 20px;
}
.grandTotalText {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: grey
}
.pageNumber {
    position: absolute;
    font-size: 12px;
    bottom: 30px;
    left: 0px;
    right: 0px;
    text-align: center;
    color: grey;
}
.details-pdf {
    display: flex;
    flex-direction: column;
    color:black;
    padding-left: 20px;
}

.notes {
    margin-right: 20px
}
.notes p {
    margin-right: 10px
}